import { useMsal } from "@azure/msal-react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Alert, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import validator from "validator";
import { loginRequest, sendMail } from "../authConfig";
import { OdesliMail } from "../services/MailService";
import { StahniSoubor } from "../services/PojService";
import { VratUzivatele } from "../services/UserService";
import { ZKEmailDialog }   from "./Dialogy";

export default function EmailDokumenty(props) {
  console.log(props.email);
  
  let [docs, setDocs] = useState([]);
  let [uziv, setUziv] = useState({});
  let [emailDialogOpen, setEmailDialogOpen] = useState(false);
  let [odeslatEmail, setOdeslatEmail] = useState(false);
  let [status, setStatus] = useState(0);
  const { instance, accounts } = useMsal();

  const email = accounts[0].username;

  const request = {
    ...sendMail,
    account: accounts[0]
  };

  async function vratToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    try {
      let respToken = await instance.acquireTokenSilent(request);
      return respToken.accessToken;
    } catch (e) {
      let respToken = await instance.acquireTokenPopup(request);
      return respToken.accessToken;
    }
  }

  function jeEmailNevalidni() {
    if ((props.email === "") || (!validator.isEmail(props.email))) return true
    else return false;
  }

  const handleSendEmail = () => {
    setEmailDialogOpen(true);
  }

  useEffect(() => {
    if (odeslatEmail) {
      setOdeslatEmail(false);
      vratToken().then((token) => {
        StahniSoubor(token, "ZELENA_KARTA", props.sml.id, props.poj.prints.zelenaKarta,true).then(docsTmp => {
          console.log(docsTmp);
          if (docsTmp.data) {
            setDocs(docsTmp.data);
          }
        });

        VratUzivatele(token).then(uzivTmp => {
          console.log(uzivTmp);
          if (uzivTmp.data) setUziv(uzivTmp.data);
        });
      });
    }
  }, [odeslatEmail]);

  useEffect(() => {
    if (docs.length > 0) {
      console.log('odesilam email');
      odeslatEmailKlient();
      setDocs([]);
    } 
  }, [docs]);

  const odeslatEmailKlient = () => {
    let odkaz = '';
    switch (props.poj.poj) {
      case 'GCP':
        odkaz = '<a href="https://www.unileasing.cz/pro-zajemce/pojisteni/#tab-00-generali-ceska-pojistovna">https://www.unileasing.cz/pro-zajemce/pojisteni/#tab-00-generali-ceska-pojistovna/</a>';
        break;
      case 'KOOP':
        odkaz = '<a href="https://www.unileasing.cz/pro-zajemce/pojisteni/#tab-01-kooperativa-pojistovna">https://www.unileasing.cz/pro-zajemce/pojisteni/#tab-01-kooperativa-pojistovna</a>';
        break;
      case 'CPP':
        odkaz = '<a href="https://www.unileasing.cz/pro-zajemce/pojisteni/#tab-02-ceska-podnikatelska-pojistovna">https://www.unileasing.cz/pro-zajemce/pojisteni/#tab-02-ceska-podnikatelska-pojistovna</a>';
        break;
    }

    const Zprava = {
      message: {
        subject: 'Zelená karta k pojistné smlouvě č. ' + props.poj.cislopojsmlouvy,
        body: {
          contentType: 'HTML',
          content:
            'Dobrý den,<br /><br />' +
            'na smlouvě ' + props.sml.smlouva + ' byla sjednána nová pojistná smlouva č. ' + props.poj.cislopojsmlouvy + ' (' + props.poj.pojistovna + ') k vozidlu ' + props.sml.nazev
            + ', VIN: ' + props.VIN + '<br /><br />'
            + 'V příloze Vám zasíláme zelenou kartu. Další dokumenty k pojištění naleznete na našich webových stránkách:<br/> '
            + odkaz
            + '<br/><br/>'
            + 'Za UNILEASING a.s.<br />'
            + uziv.jmeno + ' ' + uziv.prijmeni + ',<br />pobočka: ' + uziv.pobocka_text
            + '<br />Telefon: ' + uziv.mobil
            + '<br />Email: ' + uziv.email
        },
        toRecipients: [
          {
            emailAddress: {
              address: props.email
            }
          }
        ],
        attachments: [
          {
            '@odata.type': '#microsoft.graph.fileAttachment',
            name: 'zelena_karta.pdf',
            contentType: 'application/pdf',
            contentBytes: docs
          }
        ]
        // bccRecipients: [
        //     {
        //         emailAddress: {
        //           address: "logger@unileasing.cz"
        //         }
        //     },   
        //     {
        //       emailAddress: {
        //         address: email
        //       }
        //   },                                 
        // ],            
      },
      saveToSentItems: 'true'
    };

    instance.acquireTokenSilent(request).then((response) => {
      OdesliMail(response.accessToken, email, Zprava).then(response => {
        setStatus(response.status);
      });
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        OdesliMail(response.accessToken, email, Zprava).then(response => {
          setStatus(response.status);
        });
      });
    });
  }

  return (
    <div>
      {/* <Button  component="label" variant="outlined" startIcon={<EmailOutlinedIcon sx={{color:'white'}} />} sx={{fontSize: '11px',backgroundColor:'#dd9234', borderColor:'#dd9234',color:'white' }}>Informovat centrálu o platbě</Button> */}
      <Box sx={{display:"flex",gap:1,flexDirection:{xs:"column",md:"row"}}}>
      <Button disabled={Boolean(jeEmailNevalidni())} onClick={handleSendEmail} component="label" variant="outlined" startIcon={<EmailOutlinedIcon sx={{ color: '#dd9234' }} />} sx={{ borderColor: '#dd9234', color: '#dd9234'}}>Odeslat zelenou kartu</Button>
      {status === 202 ? <Alert severity="success">Email úspěšně odeslán!</Alert> : <p></p>}
      {status > 0 && status !== 202 ? <Alert severity="error">Problém s odesláním emailu. Kontaktujte IT oddělení.</Alert> : <p></p>}
      </Box>
      <ZKEmailDialog email={props.email} dialogOpen={emailDialogOpen} setDialogOpen={setEmailDialogOpen} setPotvrzeno={setOdeslatEmail}></ZKEmailDialog>
    </div>
  );

}